<template>
  <v-card-text class="mt-5">
    <v-form
      ref="registerForm"
      @submit.prevent="handleFormSubmit"
    >
      <v-text-field
        v-model="userData.full_name"
        dense
        outlined
        :label="$t('Fullname')"
        :rules="[validators.required]"
        :placeholder="$t('Fullname')"
        hide-details="auto"
        class="mb-6"
        validate-on-blur
      ></v-text-field>
      <v-text-field
        ref="phoneNumberField"
        v-model="userData.phone"
        dense
        outlined
        type="tel"
        :label="$t('PhoneNumber')"
        :placeholder="$t('PhoneNumber')"
        :rules="[validators.required, validators.phoneNumberValidator]"
        hide-details="auto"
        class="mb-3"
        validate-on-blur
      >
        <template
          v-slot:append-outer
        >
          <v-btn
            v-if="isPhoneChanged"
            outlined
            style="top: -7px"
            :loading="checkingPhone"
            @click="checkPhoneExisted"
          >
            {{ $t('Check') }}
          </v-btn>
        </template>
      </v-text-field>

      <v-select
        v-model="userData.gender"
        dense
        outlined
        :label="$t('Gender')"
        :placeholder="$t('Gender')"
        :items="[
          {text: $t('Male'), value: 1},
          {text: $t('Female'), value: 2},
          {text: $t('Other'), value: 0}
        ]"
        :rules="[validators.required]"
        hide-details="auto"
        class="mb-6"
        validate-on-blur
      ></v-select>

      <v-autocomplete
        v-model="userData.year_of_birth"
        dense
        outlined
        :items="yearsOfBirth"
        :label="$t('YearOfBirth')"
        :placeholder="$t('YearOfBirth')"
        :rules="[validators.required]"
        hide-details="auto"
        class="mb-6"
        validate-on-blur
      ></v-autocomplete>

      <v-row
        v-if="userData.id_user"
        justify="space-around"
      >
        <v-col
          cols="6"
          md="3"
          class="text-center"
        >
          <Upload
            v-model="faceImageUpload"
            :auto-sizing="true"
            :initial-image="userData.face_image_url"
          />
          <div>{{ $t('FaceImage') }}</div>
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="text-center"
        >
          <Upload
            v-model="faceMaskImageUpload"
            :auto-sizing="true"
            :initial-image="userData.face_mask_image_url"
          />
          <div>{{ $t('FaceWithMaskImage') }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            block
            color="primary"
            type="submit"
            :loading="loading"
          >
            {{ $t('Submit') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            outlined
            block
            @click="$router.push({name: 'staff-user-detail', params: {id: $router.currentRoute.params.id}})"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import Upload from '@/components/Upload/Upload.vue'
import AccountService from '@/services/AccountService'
import UploadService from '@/services/UploadService'
import store from '@/store'
import { useRouter } from '@core/utils'
import { alphaValidator, phoneNumberValidator, required } from '@core/utils/validation'
import { computed, inject, ref } from '@vue/composition-api'

export default {
  components: { Upload },
  setup() {
    const registerForm = ref(null)
    const phoneNumberField = ref(null)
    const { router } = useRouter()
    const userId = router.currentRoute.params.id
    const faceImageUpload = ref({})
    const faceMaskImageUpload = ref({})
    let faceImageId = null
    let faceMaskImageId = null
    const loading = ref(false)
    const checkingPhone = ref(false)
    const userData = inject('userData', {})
    const getUserData = inject('getUserData', async () => {})
    const currentPhone = ref(userData.value.phone)

    const isPhoneChanged = computed(() => !!currentPhone.value && currentPhone.value !== userData.value.phone)

    const checkPhoneExisted = async () => {
      const isPhoneNumberValid = phoneNumberField.value.validate()

      if (isPhoneNumberValid) {
        checkingPhone.value = true
        try {
          const resp = await AccountService.checkPhoneNumber({
            phone: userData.value.phone,
          })
          const isPhoneNumberExisted = resp.data.data.result

          if (isPhoneNumberExisted) {
            store.commit('snackbar/show', {
              message: 'This phone number has already existed',
              type: 'error',
            })
          } else {
            store.commit('snackbar/show', {
              message: 'This phone number is available',
              type: 'success',
            })
          }
        } catch {
        } finally {
          checkingPhone.value = false
        }
      }
    }

    const handleFormSubmit = async () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return
      loading.value = true

      try {
        await faceImageUpload.value
          .asyncBase64('image/jpeg', 0.8)
          .then(async base64 => {
            await UploadService.uploadFile({
              note: 'FaceImage',
              files: [base64],
              is_face: true,
            }).then(res => {
              faceImageId = res.data?.data?.files?.[0]?.id_file
            })
          })
          .catch(error => {
            console.error(error)
            store.commit('snackbar/show', {
              message: 'PleaseUpload',
              type: 'error',
            })
          })

        await faceMaskImageUpload.value
          .asyncBase64('image/jpeg', 0.8)
          .then(async base64 => {
            await UploadService.uploadFile({
              note: 'faceMaskImage',
              files: [base64],
              is_face: true,
            }).then(res => {
              faceMaskImageId = res.data?.data?.files?.[0]?.id_file
            })
          })
          .catch(error => {
            console.error(error)
            store.commit('snackbar/show', {
              message: 'PleaseUpload',
              type: 'error',
            })
          })

        if (faceMaskImageId && faceImageId) {
          const payload = {
            full_name: userData.value.full_name,
            gender: userData.value.gender,
            year_of_birth: userData.value.year_of_birth,
            face_image: faceImageId.toString(),
            face_mask_image: faceMaskImageId.toString(),
            phone: userData.value.phone,
          }

          await AccountService.updateUserInfo(userId, payload)

          store.commit('snackbar/show', {
            type: 'success',
            message: 'Update Successfully',
          })
          await getUserData()
          router.push({ name: 'staff-user-detail', params: { id: userId } })
        }
      } catch {
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      checkingPhone,
      checkPhoneExisted,
      userData,
      faceImageUpload,
      faceMaskImageUpload,
      handleFormSubmit,
      validators: {
        required,
        phoneNumberValidator,
        alphaValidator,
      },
      isPhoneChanged,

      // Template Refs
      registerForm,
      phoneNumberField,
    }
  },
}
</script>
